<template>
  <div class="assets-layout">
    <div class="d-flex flex-row align-center justify-space-between">
      <div>
        <router-link
          class="assets-layout__link text-h5 text-md-h4 font-weight-bold mr-2"
          active-class="assets-layout__link--active"
          exact-path
          :to="assetsLink"
          >Assets</router-link
        >
        <router-link
          class="assets-layout__link text-h5 text-md-h4 font-weight-bold ml-4"
          active-class="assets-layout__link--active"
          :to="archiveLink"
          >{{ $t('projects.archive') }}</router-link
        >
      </div>

      <div v-if="isAssetsPage">
        <div v-if="media.isMobile">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <icon-button v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-dots-horizontal
                </v-icon>
              </icon-button>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-icon right>mdi-plus</v-icon>
                  Add asset
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-icon right>mdi-file-download</v-icon>
                  {{ $t('button.export') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>
                  <v-icon right>mdi-file-upload</v-icon>
                  {{ $t('button.import') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div v-else>
          <v-btn color="primary" class="mr-3" large elevation="0">
            Add asset
            <v-icon right>mdi-plus</v-icon>
          </v-btn>

          <v-btn color="secondary" class="primary--text mr-3" large elevation="0">
            {{ $t('button.export') }}
            <v-icon right>mdi-file-download</v-icon>
          </v-btn>
          <v-btn color="secondary" class="primary--text" large elevation="0">
            {{ $t('button.import') }}
            <v-icon right>mdi-file-upload</v-icon>
          </v-btn>
        </div>
      </div>

      <template v-else>
        <v-menu v-if="media.isMobile" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <icon-button v-bind="attrs" v-on="on">
              <v-icon>
                mdi-dots-horizontal
              </v-icon>
            </icon-button>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-icon right>mdi-file-download</v-icon>
                {{ $t('button.export') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn v-else color="secondary" large class="primary--text" elevation="0">
          {{ $t('button.export') }}
          <v-icon right>mdi-file-download</v-icon>
        </v-btn>
      </template>
    </div>

    <router-view :send-request.sync="sendRequest" />
  </div>
</template>

<script>
// Components
import IconButton from '@/components/IconButton.vue';

// Constants
import { ASSETS, ASSETS_ARCHIVE } from '@/constants/routes';

export default {
  name: 'AssetsLayout',

  components: {
    IconButton,
  },

  inject: ['media'],

  data() {
    return {
      pages: {
        assets: this.$route.query.page || 1,
        archive: this.$route.query.page || 1,
      },
      sendRequest: false,
    };
  },

  computed: {
    assetsLink() {
      return { name: ASSETS, query: { page: this.pages.assets } };
    },

    archiveLink() {
      return { name: ASSETS_ARCHIVE, query: { page: this.pages.archive } };
    },

    isAssetsPage() {
      return this.$route.name === ASSETS;
    },
  },

  watch: {
    // eslint-disable-next-line
    '$route.query.page': function(newVal) {
      if (this.isAssetsPage) {
        this.pages.assets = newVal;
        return;
      }

      this.pages.archive = newVal;
    },
  },
};
</script>

<style lang="scss">
.assets-layout {
  height: 100%;

  &__link {
    color: rgba($--black-color-0, 0.5) !important;
    text-decoration: none;

    &--active {
      color: $--black-color-0 !important;
      text-decoration: underline;
    }
  }
}
</style>
